import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import useTranslation from 'hooks/use-translation';

type PriceParams = {
  price: number | null | undefined;
  isSpecial?: boolean;
  standardPrice?: number | undefined;
  isUnavailable?: boolean;
  hideDiscount?: boolean;
};

export function Price({ price, isSpecial, isUnavailable, standardPrice, hideDiscount }: PriceParams): JSX.Element {
  return (
    <Container>
      <PriceText isSpecial={isSpecial && !hideDiscount} isUnavailable={isUnavailable}>
        ${price?.toFixed(2)}
      </PriceText>

      {isSpecial && !hideDiscount && <StrikedOutPrice>${standardPrice?.toFixed(2)}</StrikedOutPrice>}
    </Container>
  );
}

export function PriceFrom({ price }): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container column mb={28}>
      <StartingAt>{t('common.starting-at', 'Starting at')}</StartingAt>

      <PriceText>${price.toFixed(2)}</PriceText>
    </Container>
  );
}

const getPriceColor = ({ theme, isUnavailable }): string => {
  if (isUnavailable) {
    return theme.colors.grey[70];
  }

  return theme.customized.colors.buttonsLinks;
};

const Container = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? `column` : `row`)};
  margin-bottom: 31px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 17px;
  }
  ${space}
`;

const StartingAt = styled.div`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 5px;
`;

const PriceText = styled.div`
  color: ${(props) => getPriceColor(props)};
  font-size: 31px;
  font-weight: 300;
  line-height: 36px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 26px;
  }
`;

const StrikedOutPrice = styled(PriceText)`
  color: ${({ theme }) => theme.colors.grey[70]};
  text-decoration-line: line-through;
  margin-left: 9px;
`;
